/*
@tailwind base;

@tailwind components;
*/

.bg-transparent {
  background-color: transparent
}

.bg-white {
  background-color: #FFF
}

.bg-gray-50 {
  background-color: #FAFAFA
}

.bg-gray-100 {
  background-color: #F5F5F5
}

.bg-gray-200 {
  background-color: #EEEEEE
}

.bg-red-500 {
  background-color: #F44336
}

.bg-orange {
  background-color: #FF9800
}

.bg-green-300 {
  background-color: #81C784
}

.bg-blue {
  background-color: #2196F3
}

.bg-amber-600 {
  background-color: #FFB300
}

.bg-green-accent-700 {
  background-color: #306431
}

.bg-green-highlight-200 {
  background-color: #D4D9D9
}

.bg-green-highlight {
  background-color: #A8C0A7
}

.hover\:bg-transparent:hover {
  background-color: transparent
}

.hover\:bg-gray-200:hover {
  background-color: #EEEEEE
}

.focus\:bg-transparent:focus {
  background-color: transparent
}

.border-gray-400 {
  border-color: #BDBDBD
}

.rounded-4 {
  border-radius: .4rem
}

.rounded-8 {
  border-radius: .8rem
}

.rounded-16 {
  border-radius: 1.6rem
}

.rounded-none {
  border-radius: 0
}

.rounded {
  border-radius: .4rem
}

.rounded-lg {
  border-radius: .8rem
}

.border-0 {
  border-width: 0
}

.border-1 {
  border-width: 1px
}

.border-b-1 {
  border-bottom-width: 1px
}

.cursor-default {
  cursor: default
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.grid {
  display: grid
}

.table {
  display: table
}

.hidden {
  display: none
}

.flex-row {
  flex-direction: row
}

.flex-row-reverse {
  flex-direction: row-reverse
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.items-start {
  align-items: flex-start
}

.items-end {
  align-items: flex-end
}

.items-center {
  align-items: center
}

.self-start {
  align-self: flex-start
}

.self-center {
  align-self: center
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.content-center {
  align-content: center
}

.content-start {
  align-content: flex-start
}

.flex-1 {
  flex: 1 1 0%
}

.flex-auto {
  flex: 1 1 auto
}

.flex-grow-0 {
  flex-grow: 0
}

.flex-grow {
  flex-grow: 1
}

.flex-shrink-0 {
  flex-shrink: 0
}

.flex-shrink {
  flex-shrink: 1
}

.font-sans {
  font-family: Muli, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

.font-300 {
  font-weight: 300
}

.font-600 {
  font-weight: 600
}

.font-light {
  font-weight: 300
}

.font-normal {
  font-weight: 400
}

.font-medium {
  font-weight: 500
}

.font-semibold {
  font-weight: 600
}

.font-bold {
  font-weight: 700
}

.h-8 {
  height: 0.8rem
}

.h-16 {
  height: 1.6rem
}

.h-28 {
  height: 2.8rem
}

.h-32 {
  height: 3.2rem
}

.h-40 {
  height: 4rem
}

.h-48 {
  height: 4.8rem
}

.h-52 {
  height: 5.2rem
}

.h-64 {
  height: 6.4rem
}

.h-128 {
  height: 12.8rem
}

.h-136 {
  height: 13.6rem
}

.h-256 {
  height: 25.6rem
}

.h-320 {
  height: 32rem
}

.h-auto {
  height: auto
}

.h-full {
  height: 100%
}

.leading-none {
  line-height: 1
}

.list-none {
  list-style-type: none
}

.list-disc {
  list-style-type: disc
}

.m-4 {
  margin: 0.4rem
}

.m-6 {
  margin: 0.6rem
}

.m-8 {
  margin: 0.8rem
}

.m-12 {
  margin: 1.2rem
}

.m-32 {
  margin: 3.2rem
}

.m-auto {
  margin: auto
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0
}

.mx-4 {
  margin-left: 0.4rem;
  margin-right: 0.4rem
}

.my-8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem
}

.mx-8 {
  margin-left: 0.8rem;
  margin-right: 0.8rem
}

.my-10 {
  margin-top: 1.0rem;
  margin-bottom: 1.0rem
}

.mx-12 {
  margin-left: 1.2rem;
  margin-right: 1.2rem
}

.my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem
}

.mx-16 {
  margin-left: 1.6rem;
  margin-right: 1.6rem
}

.my-20 {
  margin-top: 2rem;
  margin-bottom: 2rem
}

.mx-24 {
  margin-left: 2.4rem;
  margin-right: 2.4rem
}

.my-32 {
  margin-top: 3.2rem;
  margin-bottom: 3.2rem
}

.my-36 {
  margin-top: 3.6rem;
  margin-bottom: 3.6rem
}

.mx-48 {
  margin-left: 4.8rem;
  margin-right: 4.8rem
}

.my-64 {
  margin-top: 6.4rem;
  margin-bottom: 6.4rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.-mx-12 {
  margin-left: -1.2rem;
  margin-right: -1.2rem
}

.mt-0 {
  margin-top: 0
}

.mb-0 {
  margin-bottom: 0
}

.mr-2 {
  margin-right: 0.2rem
}

.mb-2 {
  margin-bottom: 0.2rem
}

.ml-2 {
  margin-left: 0.2rem
}

.mt-4 {
  margin-top: 0.4rem
}

.mr-4 {
  margin-right: 0.4rem
}

.mb-4 {
  margin-bottom: 0.4rem
}

.ml-4 {
  margin-left: 0.4rem
}

.mr-6 {
  margin-right: 0.6rem
}

.mb-6 {
  margin-bottom: 0.6rem
}

.mt-8 {
  margin-top: 0.8rem
}

.mr-8 {
  margin-right: 0.8rem
}

.mb-8 {
  margin-bottom: 0.8rem
}

.ml-8 {
  margin-left: 0.8rem
}

.mt-10 {
  margin-top: 1.0rem
}

.mb-10 {
  margin-bottom: 1.0rem
}

.mt-12 {
  margin-top: 1.2rem
}

.mr-12 {
  margin-right: 1.2rem
}

.mb-12 {
  margin-bottom: 1.2rem
}

.ml-12 {
  margin-left: 1.2rem
}

.mt-16 {
  margin-top: 1.6rem
}

.mr-16 {
  margin-right: 1.6rem
}

.mb-16 {
  margin-bottom: 1.6rem
}

.ml-16 {
  margin-left: 1.6rem
}

.mt-20 {
  margin-top: 2rem
}

.mb-20 {
  margin-bottom: 2rem
}

.ml-20 {
  margin-left: 2rem
}

.mt-24 {
  margin-top: 2.4rem
}

.mb-24 {
  margin-bottom: 2.4rem
}

.ml-24 {
  margin-left: 2.4rem
}

.mr-28 {
  margin-right: 2.8rem
}

.mb-28 {
  margin-bottom: 2.8rem
}

.ml-28 {
  margin-left: 2.8rem
}

.mt-32 {
  margin-top: 3.2rem
}

.mr-32 {
  margin-right: 3.2rem
}

.mb-32 {
  margin-bottom: 3.2rem
}

.ml-32 {
  margin-left: 3.2rem
}

.mt-36 {
  margin-top: 3.6rem
}

.mb-36 {
  margin-bottom: 3.6rem
}

.ml-36 {
  margin-left: 3.6rem
}

.mb-40 {
  margin-bottom: 4rem
}

.ml-40 {
  margin-left: 4rem
}

.mt-44 {
  margin-top: 4.4rem
}

.ml-44 {
  margin-left: 4.4rem
}

.mt-48 {
  margin-top: 4.8rem
}

.mb-48 {
  margin-bottom: 4.8rem
}

.mt-56 {
  margin-top: 5.6rem
}

.mr-56 {
  margin-right: 5.6rem
}

.mb-64 {
  margin-bottom: 6.4rem
}

.mr-72 {
  margin-right: 7.2rem
}

.ml-auto {
  margin-left: auto
}

[dir='ltr'] .ltr\:ml-4,[dir='ltr'].ltr\:ml-4 {
  margin-left: 0.4rem
}

[dir='ltr'] .ltr\:ml-8,[dir='ltr'].ltr\:ml-8 {
  margin-left: 0.8rem
}

[dir='rtl'] .rtl\:mr-4,[dir='rtl'].rtl\:mr-4 {
  margin-right: 0.4rem
}

[dir='rtl'] .rtl\:mr-8,[dir='rtl'].rtl\:mr-8 {
  margin-right: 0.8rem
}

.max-h-320 {
  max-height: 32rem
}

.max-h-lg {
  max-height: 80rem
}

.max-w-288 {
  max-width: 28.8rem
}

.max-w-360 {
  max-width: 36rem
}

.max-w-384 {
  max-width: 38.4rem
}

.max-w-512 {
  max-width: 51.2rem
}

.max-w-xs {
  max-width: 32rem
}

.max-w-2xl {
  max-width: 112rem
}

.max-w-full {
  max-width: 100%
}

.min-h-64 {
  min-height: 6.4rem
}

.min-h-128 {
  min-height: 12.8rem
}

.min-h-160 {
  min-height: 16rem
}

.min-h-224 {
  min-height: 22.4rem
}

.min-h-288 {
  min-height: 28.8rem
}

.min-h-320 {
  min-height: 32rem
}

.min-w-20 {
  min-width: 2rem
}

.min-w-40 {
  min-width: 4rem
}

.min-w-64 {
  min-width: 6.4rem
}

.min-w-96 {
  min-width: 9.6rem
}

.min-w-xl {
  min-width: 96rem
}

.opacity-0 {
  opacity: 0
}

.opacity-50 {
  opacity: 0.5
}

.opacity-75 {
  opacity: 0.75
}

.overflow-auto {
  overflow: auto
}

.overflow-hidden {
  overflow: hidden
}

.overflow-x-auto {
  overflow-x: auto
}

.overflow-y-auto {
  overflow-y: auto
}

.overflow-y-visible {
  overflow-y: visible
}

.p-0 {
  padding: 0
}

.p-4 {
  padding: 0.4rem
}

.p-12 {
  padding: 1.2rem
}

.p-16 {
  padding: 1.6rem
}

.p-24 {
  padding: 2.4rem
}

.p-32 {
  padding: 3.2rem
}

.p-48 {
  padding: 4.8rem
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0
}

.px-0 {
  padding-left: 0;
  padding-right: 0
}

.py-4 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem
}

.px-4 {
  padding-left: 0.4rem;
  padding-right: 0.4rem
}

.py-6 {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem
}

.py-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem
}

.px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem
}

.py-12 {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem
}

.px-12 {
  padding-left: 1.2rem;
  padding-right: 1.2rem
}

.py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem
}

.px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem
}

.py-20 {
  padding-top: 2rem;
  padding-bottom: 2rem
}

.px-20 {
  padding-left: 2rem;
  padding-right: 2rem
}

.py-24 {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem
}

.px-24 {
  padding-left: 2.4rem;
  padding-right: 2.4rem
}

.py-32 {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem
}

.px-32 {
  padding-left: 3.2rem;
  padding-right: 3.2rem
}

.py-36 {
  padding-top: 3.6rem;
  padding-bottom: 3.6rem
}

.py-48 {
  padding-top: 4.8rem;
  padding-bottom: 4.8rem
}

.px-48 {
  padding-left: 4.8rem;
  padding-right: 4.8rem
}

.py-72 {
  padding-top: 7.2rem;
  padding-bottom: 7.2rem
}

.py-84 {
  padding-top: 8.4rem;
  padding-bottom: 8.4rem
}

.pt-0 {
  padding-top: 0
}

.pb-0 {
  padding-bottom: 0
}

.pl-0 {
  padding-left: 0
}

.pt-2 {
  padding-top: 0.2rem
}

.pr-2 {
  padding-right: 0.2rem
}

.pb-4 {
  padding-bottom: 0.4rem
}

.pl-4 {
  padding-left: 0.4rem
}

.pt-8 {
  padding-top: 0.8rem
}

.pr-8 {
  padding-right: 0.8rem
}

.pb-8 {
  padding-bottom: 0.8rem
}

.pl-8 {
  padding-left: 0.8rem
}

.pb-10 {
  padding-bottom: 1.0rem
}

.pt-12 {
  padding-top: 1.2rem
}

.pb-12 {
  padding-bottom: 1.2rem
}

.pl-12 {
  padding-left: 1.2rem
}

.pt-16 {
  padding-top: 1.6rem
}

.pr-16 {
  padding-right: 1.6rem
}

.pb-16 {
  padding-bottom: 1.6rem
}

.pl-16 {
  padding-left: 1.6rem
}

.pb-20 {
  padding-bottom: 2rem
}

.pt-24 {
  padding-top: 2.4rem
}

.pr-24 {
  padding-right: 2.4rem
}

.pb-24 {
  padding-bottom: 2.4rem
}

.pl-24 {
  padding-left: 2.4rem
}

.pt-28 {
  padding-top: 2.8rem
}

.pt-32 {
  padding-top: 3.2rem
}

.pb-32 {
  padding-bottom: 3.2rem
}

.pb-36 {
  padding-bottom: 3.6rem
}

.pl-40 {
  padding-left: 4rem
}

.pt-44 {
  padding-top: 4.4rem
}

.pt-48 {
  padding-top: 4.8rem
}

.pb-48 {
  padding-bottom: 4.8rem
}

.pl-56 {
  padding-left: 5.6rem
}

.pb-64 {
  padding-bottom: 6.4rem
}

.pl-72 {
  padding-left: 7.2rem
}

.pl-80 {
  padding-left: 8rem
}

[dir='ltr'] .ltr\:pr-48,[dir='ltr'].ltr\:pr-48 {
  padding-right: 4.8rem
}

[dir='rtl'] .rtl\:pl-48,[dir='rtl'].rtl\:pl-48 {
  padding-left: 4.8rem
}

.pointer-events-none {
  pointer-events: none
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: sticky
}

.top-0 {
  top: 0
}

.right-0 {
  right: 0
}

.bottom-0 {
  bottom: 0
}

.left-0 {
  left: 0
}

[dir='ltr'] .ltr\:right-0,[dir='ltr'].ltr\:right-0 {
  right: 0
}

[dir='rtl'] .rtl\:left-0,[dir='rtl'].rtl\:left-0 {
  left: 0
}

.shadow-1 {
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)
}

.shadow-none {
  box-shadow: none
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-black {
  color: #22292F
}

.text-white {
  color: #FFF
}

.text-gray-500 {
  color: #9E9E9E
}

.text-gray {
  color: #9E9E9E
}

.text-red-500 {
  color: #F44336
}

.text-red {
  color: #F44336
}

.text-green-500 {
  color: #4CAF50
}

.text-blue {
  color: #2196F3
}

.text-theme-primary {
  color: #516463
}

.text-green-accent {
  color: #71B93B
}

.text-offline-status {
  color: #060606
}

.text-pending-status {
  color: #CFD2D2
}

.text-no-policy-status {
  color: #516463
}

.text-not-assessed-status {
  color: #07827C
}

.text-11 {
  font-size: 1.1rem
}

.text-12 {
  font-size: 1.2rem
}

.text-13 {
  font-size: 1.3rem
}

.text-14 {
  font-size: 1.4rem
}

.text-16 {
  font-size: 1.6rem
}

.text-18 {
  font-size: 1.8rem
}

.text-20 {
  font-size: 2rem
}

.text-24 {
  font-size: 2.4rem
}

.text-28 {
  font-size: 2.8rem
}

.text-32 {
  font-size: 3.2rem
}

.text-40 {
  font-size: 4rem
}

.text-64 {
  font-size: 6.4rem
}

.text-96 {
  font-size: 9.6rem
}

.text-xs {
  font-size: 1.2rem
}

.text-4xl {
  font-size: 3.6rem
}

.italic {
  font-style: italic
}

.uppercase {
  text-transform: uppercase
}

.lowercase {
  text-transform: lowercase
}

.capitalize {
  text-transform: capitalize
}

.normal-case {
  text-transform: none
}

.underline {
  text-decoration: underline
}

.line-through {
  text-decoration: line-through
}

.select-none {
  -webkit-user-select: none;
          user-select: none
}

.visible {
  visibility: visible
}

.invisible {
  visibility: hidden
}

.whitespace-no-wrap {
  white-space: nowrap
}

.break-all {
  word-break: break-all
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.w-16 {
  width: 1.6rem
}

.w-24 {
  width: 2.4rem
}

.w-40 {
  width: 4rem
}

.w-48 {
  width: 4.8rem
}

.w-64 {
  width: 6.4rem
}

.w-92 {
  width: 9.2rem
}

.w-128 {
  width: 12.8rem
}

.w-192 {
  width: 19.2rem
}

.w-200 {
  width: 20rem
}

.w-224 {
  width: 22.4rem
}

.w-288 {
  width: 28.8rem
}

.w-320 {
  width: 32rem
}

.w-360 {
  width: 36rem
}

.w-400 {
  width: 40rem
}

.w-512 {
  width: 51.2rem
}

.w-640 {
  width: 64rem
}

.w-xs {
  width: 32rem
}

.w-sm {
  width: 48rem
}

.w-lg {
  width: 80rem
}

.w-1\/2 {
  width: 50%
}

.w-1\/4 {
  width: 25%
}

.w-full {
  width: 100%
}

.z-0 {
  z-index: 0
}

.z-10 {
  z-index: 10
}

.z-9999 {
  z-index: 9999
}

.gap-8 {
  grid-gap: 0.8rem;
  gap: 0.8rem
}

.gap-12 {
  grid-gap: 1.2rem;
  gap: 1.2rem
}

.gap-16 {
  grid-gap: 1.6rem;
  gap: 1.6rem
}

.gap-48 {
  grid-gap: 4.8rem;
  gap: 4.8rem
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
}

.scale-150 {
  --transform-scale-x: 1.5;
  --transform-scale-y: 1.5
}

.rotate-180 {
  --transform-rotate: 180deg
}

.transition-all {
  transition-property: all
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform
}

.transition-transform {
  transition-property: transform
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.duration-150 {
  transition-duration: 150ms
}

.duration-200 {
  transition-duration: 200ms
}

@media (min-width: 600px) {

  .sm\:flex {
    display: flex
  }

  .sm\:mb-24 {
    margin-bottom: 2.4rem
  }

  .sm\:mt-88 {
    margin-top: 8.8rem
  }

  .sm\:overflow-y-visible {
    overflow-y: visible
  }

  .sm\:p-24 {
    padding: 2.4rem
  }

  .sm\:p-32 {
    padding: 3.2rem
  }

  .sm\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem
  }

  .sm\:py-24 {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem
  }

  .sm\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .sm\:pb-0 {
    padding-bottom: 0
  }

  [dir='ltr'] .sm\:ltr\:text-left,[dir='ltr'].sm\:ltr\:text-left {
    text-align: left
  }

  [dir='rtl'] .sm\:rtl\:text-right,[dir='rtl'].sm\:rtl\:text-right {
    text-align: right
  }

  .sm\:text-40 {
    font-size: 4rem
  }

  .sm\:w-1\/2 {
    width: 50%
  }
}

@media (min-width: 960px) {

  .md\:flex {
    display: flex
  }

  .md\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .md\:w-1\/2 {
    width: 50%
  }

  .md\:w-1\/3 {
    width: 33.333333%
  }
}

@media (min-width: 1280px) {

  .lg\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  .lg\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .lg\:px-60 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  .lg\:pr-0 {
    padding-right: 0
  }

  [dir='ltr'] .lg\:ltr\:pl-0,[dir='ltr'].lg\:ltr\:pl-0 {
    padding-left: 0
  }

  [dir='rtl'] .lg\:rtl\:pr-0,[dir='rtl'].lg\:rtl\:pr-0 {
    padding-right: 0
  }

  .lg\:visible {
    visibility: visible
  }
}